import React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import VideoEmbed from "../../components/video-embed"
import { Link } from "gatsby"
import Sidebar from "../../components/sidebar"

export default class BringingLoveToMyPeople extends React.Component {
  render() {
    return (
      <Layout title="Bringing Love to My People" sectionName="Stories" sectionPath="/stories">
        <Seo title="Bringing Love to My People"/>
        <div className="container">
          <div className="row">
            <section className="col-sm-9">
              <VideoEmbed vimeoId="231561160" title="Bringing Love to My People" useCinema={true} author="Weston" location="Arizona" />

              <p>I found hope in Jesus, and now I get to be Hope for Native America as I bring God's love to my people. I grew up seeing my father beat my mother and I grew up without hope for my future. My mother was trapped, not knowing where to turn, and anger was taking over my life. The only thing I wanted was a phone call, telling me my father died. I told myself I would never become my father, but I found myself drinking, smoking, and taking advantage of relationships.</p>

              <p>One day I realized I was becoming the person I said that I would never be; my father. I hated it so much that I wanted to take my life, because I thought there was no way out. Every time I was going to kill myself, I thought about my mom telling me that Jesus loves me. But, I didn't want anything to do with Jesus because I thought He was a "white man's god." When I thought of Jesus, I thought of persecution committed by the Europeans in the name of "Christianity." Still, every time I thought about this Jesus guy I needed to know more, because everything else I tried had failed. The only thing I hadn't tried yet, the only thing I didn't look into was this Jesus guy.</p>

              <p className="story-callout">"Every time I was going to kill myself, I thought about my mom telling me that Jesus loves me."</p>

              <p>When I started to read about Jesus in the Bible, it changed everything. I didn't read about a "white man's god." I read about a brown-skinned man Who was homeless, Who was beaten until unrecognizable, and Whose land was taken from Him. I read about someone Who relates to me. I thought, "*Why have people been telling me that this guy's not for me, but every time I read about Him, He relates to me? He knows me.*"</p>

              <p>Everything changed when I read about what He did on the cross, because I realized how much He loves me. He was willing to die for someone as small as me; He was willing to give me a second chance. I started to see that Jesus came to rescue me, not to hurt me or my people. Through Jesus I finally found the love of a father.</p>

              <p>Every year, Hope for Native America givers help make possible a month-long summer trip to Native American communities, where Native young people share the hope they have found in Jesus. This group is called "On Eagles' Wings." When you become hope for Native America through sending an On Eagles' Wings team member, you make it possible for Native Americans across this continent to hear about Who Jesus really is. It's time for a Jesus generation in Native America.</p>

              <p>Thank you for being Hope for Native America.</p>

              <p className="mt-5 text-center">
                <Link className="btn btn-secondary" to="/get-involved/equip-a-leader">Equip A Leader</Link>
              </p>

            </section>
            <aside className="col-sm-3">
              <h2><span>Here's how <strong>you</strong> can</span><br/><em>get involved!</em></h2>
              <Sidebar title="Equip a Leader" 
                      description={`Equip young Native Christian leaders to reach their communities for Jesus and to be a living example of hope to broken young people.`}
                      url="/get-involved/equip-a-leader"
                      thumbnail="/video-thumbnails/equip-a-leader.jpg" />
            </aside>
          </div>
        </div>
      </Layout>
    )
  }
}
